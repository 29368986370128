import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, FormControlLabel, Grid, MenuItem, Stack, Switch, Typography, } from '@mui/material';
// components
import FormProvider, { RHFAutocomplete, RHFSelect, RHFTextField, RHFUploadAvatar, } from '../../../components/hook-form';
import Label from '../../../components/label';
// @mui
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import RHFDate from '../../../components/hook-form/RHFDate';
import { useLocales } from '../../../locales';
import { createPatient, getPatients } from '../../../redux/slices/patient/patientThunk';
import { resetStep } from '../../../redux/slices/prothese/protheseSlice';
import { dispatch } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useDebounce from '../../../hooks/useDebounce';
export default function PatientNewForm({ isEdit = false, currentUser, setOpenConfirm, setCurrentId, handleNext, }) {
    const date = new Date();
    const navigate = useNavigate();
    const { translate } = useLocales();
    const { data } = useSelector((state) => state.patient);
    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
    });
    const { enqueueSnackbar } = useSnackbar();
    const defaultValues = useMemo(() => ({
        name: currentUser?.name || '',
        phone: currentUser?.phone || '',
        birthday: '',
        description: '',
        file: null,
        gender: '',
        isSelectedUser: null,
    }), []);
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });
    const { watch, control, setValue, handleSubmit, formState: { isSubmitting }, } = methods;
    const values = watch();
    useEffect(() => {
        dispatch(getPatients({ limit: 25, page: 1 }));
    }, []);
    useDebounce(() => {
        dispatch(getPatients({
            limit: 25,
            search: values?.name,
            page: 1,
        }));
    }, 400, [values?.name]);
    const formatedPatientData = data.map((patient) => {
        return {
            label: patient.phone ? patient?.name + '  |  ' + patient.phone : patient?.name,
            value: patient.id,
        };
    });
    const onSubmit = async (dataPatient) => {
        const { file, idSelectedUser, isSelectedUser, ...data } = values;
        try {
            if (isSelectedUser) {
                dispatch(resetStep());
                navigate(PATH_DASHBOARD.patientForm
                    .replace(':step', '2')
                    .replace(':id?', idSelectedUser)
                    .replace(':treatmentId?', ''));
                handleNext();
            }
            else {
                if (!data?.phone)
                    delete data?.phone;
                if (!data?.gender)
                    delete data?.gender;
                if (!data?.birthday)
                    delete data?.birthday;
                const result = (await dispatch(createPatient({ params: !values.file ? { ...data } : { ...data, file: values.file } })));
                if (result?.payload?.statusCode === 200) {
                    setOpenConfirm(true);
                    setCurrentId(result?.payload?.data?.id);
                }
                else {
                    enqueueSnackbar(result?.payload?.message, { variant: 'error' });
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const styles = {
        color: 'text.secondary',
        marginBottom: '1rem',
    };
    const card = {
        padding: '2rem',
        marginTop: '1rem',
    };
    return (_jsxs(FormProvider, { methods: methods, onSubmit: handleSubmit(onSubmit), children: [_jsxs(Card, { sx: card, children: [_jsx(Typography, { variant: "h4", sx: styles, children: `${translate('patient_info.title')}` }), _jsxs(Grid, { container: true, spacing: 3, justifyContent: 'center', alignItems: 'center', children: [_jsx(Grid, { item: true, xs: 12, md: 8, children: _jsxs(Box, { rowGap: 3, columnGap: 2, display: "grid", gridTemplateColumns: {
                                        xs: 'repeat(1, 1fr)',
                                        sm: 'repeat(2, 1fr)',
                                    }, children: [_jsx(RHFAutocomplete, { name: "name", label: `${translate('patient_info.name')}`, options: formatedPatientData, freeSolo: true, onInputChange: (event, newValue) => {
                                                setValue('name', newValue);
                                                setValue('gender', '');
                                                setValue('phone', '');
                                                setValue('birthday', '');
                                                setValue('isSelectedUser', false);
                                            }, onChange: (event, newValue) => {
                                                setValue('idSelectedUser', newValue?.value || '');
                                                setValue('isSelectedUser', newValue?.value ? true : false);
                                                setValue('name', newValue?.label || newValue || '');
                                                const user = data?.filter((el) => el?.id == newValue?.value)[0];
                                                setValue('gender', user?.gender);
                                                setValue('phone', user?.phone);
                                                setValue('birthday', user?.birthday);
                                            } }), _jsx(RHFDate, { label: `${translate('patient_info.date')}`, disabled: values?.isSelectedUser, name: "birthday", minDate: new Date('1900-01-01'), maxDate: new Date(`${date.getFullYear() - 3}-01-01`) }), _jsx(RHFSelect, { name: `gender`, label: `${translate('patient_info.gender')}`, disabled: values?.isSelectedUser, children: [
                                                { label: `${translate('male')}`, value: 'male' },
                                                { label: `${translate('female')}`, value: 'female' },
                                            ].map((gender) => (_jsx(MenuItem, { value: gender.value, children: `${translate(gender?.label)}` }, gender.value))) }), _jsx(RHFTextField, { disabled: values?.isSelectedUser, name: "phone", type: "number", label: `${translate('patient_info.phone')}` })] }) }), _jsxs(Grid, { item: true, xs: 12, md: 4, children: [isEdit && (_jsx(Label, { color: values.status === 'active' ? 'success' : 'error', sx: { textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }, children: values.status })), _jsx(Box, { sx: { mb: 5 }, children: _jsx(RHFUploadAvatar, { name: "file", maxSize: 3145728, label: "Profile Picture" }) }), isEdit && (_jsx(FormControlLabel, { labelPlacement: "start", control: _jsx(Controller, { name: "status", control: control, render: ({ field }) => (_jsx(Switch, { ...field, checked: field.value !== 'active', onChange: (event) => field.onChange(event.target.checked ? 'banned' : 'active') })) }), label: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", sx: { mb: 0.5 }, children: "Banned" }), _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: "Apply disable account" })] }), sx: { mx: 0, mb: 3, width: 1, justifyContent: 'space-between' } }))] }), _jsx(Grid, { item: true, xs: 12, md: 4 })] })] }), _jsxs(Card, { sx: card, children: [_jsx(Typography, { variant: "h4", sx: styles, children: `${translate('patient_info.addition')}` }), _jsx(RHFTextField, { name: "description", label: `${translate('Enter your message here')}` }), _jsx(Stack, { alignItems: "flex-end", sx: { mt: 3 }, children: _jsx(LoadingButton, { type: "submit", variant: "contained", loading: isSubmitting, children: !isEdit ? `${translate('patient_info.create')}` : `${translate('patient_info.save')}` }) })] })] }));
}
